import { useState } from 'react';
import { AssetV2 } from 'types/asset.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import AssetManagementService from '../services/asset-management.service';
import { useAssetManagementConfigContext } from '../context/asset-management-config.context';
import { useAMFetch, useAMNavigation } from '.';

const useAMRelease = (
    latestAsset: AssetV2<unknown, unknown>,
    onClose?: () => void,
    onSetFreshAsset?: (asset: AssetV2<unknown, unknown>) => void
): {
    releaseAsset: () => Promise<void>;
    waiting: boolean;
    confirmRelease: boolean;
    setConfirmRelease: React.Dispatch<React.SetStateAction<boolean>>;
    setAvailableAndRelease: () => Promise<void>;
} => {
    const { languageNameSpace, storeName } = useAssetManagementConfigContext();
    const { fetchAssets } = useAMFetch();
    const { matchPathToStatus } = useAMNavigation();
    const [waiting, setWaiting] = useState(false);
    const [confirmRelease, setConfirmRelease] = useState(false);

    // Refresh available views after release
    const refreshAfterRelease = (response: AssetV2<unknown, unknown>) => {
        if (onSetFreshAsset) onSetFreshAsset(response);
        fetchAssets(true, 'available');
        ComponentStoreHelpers.setModel(storeName, 'collectionsContent', {});
        SnackbarUtils.success(Translation.get('snackbar.released', languageNameSpace));
        setWaiting(false);
    };

    // Release an asset that is allready available
    const releaseAsset = async () => {
        setWaiting(true);
        const response = await AssetManagementService.releaseAsset(latestAsset._id);
        if (response) {
            refreshAfterRelease(response);
            if (onClose) onClose();
        }
    };

    // Realease an asset that is in draft.
    const setAvailableAndRelease = async () => {
        setWaiting(true);
        const newAsset: AssetV2<unknown, unknown> = { ...latestAsset, status: 'available' };
        const response = await AssetManagementService.patchAsset(newAsset);
        if (response) {
            refreshAfterRelease(response);
            fetchAssets(true, 'draft');
            matchPathToStatus('available');
            if (onClose) onClose();
        }
    };

    return {
        releaseAsset,
        waiting,
        confirmRelease,
        setConfirmRelease,
        setAvailableAndRelease
    };
};

export default useAMRelease;
