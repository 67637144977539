import { AssetV2 } from 'types/asset.type';
import { Right } from '../types/asset-management-right.type';

// Check if the user can release the asset based on the asset status and the users rights.
const canRelease = (userHasRight: (right: Right) => boolean, asset: AssetV2<unknown, unknown>): boolean => {
    if (!asset.status) return false;
    if (!['draft', 'available'].includes(asset.status)) return false;
    if ((asset.version || 0) <= (asset.releasedVersion || 0)) return false;
    if (!userHasRight('management')) return false;
    return true;
};

export { canRelease };
