import { AssetV2 } from 'types/asset.type';
import { Right } from '../types/asset-management-right.type';
import { isMyAsset } from './isMyAsset';

const canDeletePermanently = (asset: AssetV2<unknown, unknown>, userHasRight: (right: Right) => boolean): boolean => {
    if (userHasRight('management')) return true; // Managers can delete any asset.
    if (userHasRight('write') && asset.status !== 'available' && isMyAsset(asset)) return true; // Writers can delete their own draft assets.
    return false;
};

export { canDeletePermanently };
