import { AssetType } from 'types/asset.type';
import { LanguageNameSpace } from '../types/asset-management-language-name-space.type';

// Get the namespace to use in translations for the given asset type.
const getLanguageNameSpace = (type: AssetType): LanguageNameSpace => {
    // Temporary fix for creative management because the old version uses the name intended for V2;
    if (type === 'creative') return 'creative-management-v2';

    return `${type}-management` as LanguageNameSpace;
};

export { getLanguageNameSpace };
