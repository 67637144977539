import { AssetStatus } from 'types/asset.type';
import { FilterSetup } from 'types/filter.type';

// Filter out the collections filter if assets are not available, because collections only have meaning for available assets.
const matchFilterSetupToStatus = (status: AssetStatus, filterSetup?: FilterSetup): FilterSetup | undefined => {
    if (!filterSetup) return undefined;
    if (status === 'available') return filterSetup;
    return filterSetup.filter((filter) => filter.name !== 'collections');
};

export { matchFilterSetupToStatus };
