import React, { useEffect, useMemo, useRef, useState } from 'react';
import ImageModifierService from 'services/image-modifier/image-modifier.service';
import ImageFileService from 'services/image-file/image.service';
import axios from 'axios';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import AssetEditorActionRow from 'components/assets/AssetEditor/components/AssetEditorActionsRow/asset-editor-actions';
import AssetEditorControllerStepper from 'components/assets/AssetEditor/components/AssetEditorControllerStepper';
import StepperLoading from 'components/assets/AssetEditor/components/AssetEditorControllerStepper/components/stepper-loading';
import Translation from 'components/data/Translation';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import useAssetEditorStepperHook from 'components/assets/AssetEditor/hooks/asset-editor-stepper-hook';
import AlertBoxType from 'components/ui-components/AlertBox/interfaces/alert-box-type';
import { AssetEditorAlertBoxProps } from 'components/assets/AssetEditor/components/AssetEditorAlertBox';
import AssetEditorHelper from 'components/assets/AssetEditor/helpers/asset-editor-helper';
import ComponentStore from 'components/data/ComponentStore';
import CropInputRow from 'components/assets/AssetGalleryCropper/components/crop-input-row';
import AECropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';
import { AssetDimensions } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryData';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import imageCompressorSteps from './data/image-compressor-steps';
import ImageCompressorMode from './components/image-compressor-slider-row';
import ImageCompressorState, { CompressorMode } from './interfaces/image-compressor-state';
import ImageExportHelper from './helpers/image-export-helper';
import ImageCompressorHelper from './helpers/image-compressor-helper';

const DEFAULT_ALERT_Box: AssetEditorAlertBoxProps = { title: '', subTitle: '', type: 'success' };
const DEFAULT_IMAGE_DIMENSIONS: AssetDimensions = { width: 0, height: 0 };

import './styles/main.scss';

/**
 * Component for compressing images in the asset editor.
 */
const ImageCompressorController: React.FC = () => {
    const { stepperStep, handleNext, handleBack } = useAssetEditorStepperHook(); // Hook to manage the asset editor stepper.
    const [alertBox, setAlertBox] = useState<AssetEditorAlertBoxProps>(DEFAULT_ALERT_Box); // State to manage the alert box.
    const [newSize, setNewSize] = useState(0); // State to manage the new size of the image.
    const cancelTokenSource = useRef(axios.CancelToken.source());
    const maxImageDimensions = useRef<AssetDimensions>(DEFAULT_IMAGE_DIMENSIONS); // Ref to store the max image dimensions.
    const [currentImageDimensions, setCurrentImageDimensions] = useState<AssetDimensions>(DEFAULT_IMAGE_DIMENSIONS); // State to manage the current image dimensions.

    const { originalAssetSrc, modifiedAssetSrc, croppedModifiedAssetSrc, loading, imageCompressorState } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: {
            originalAssetSrc: 'originalAssetSrc',
            modifiedAssetSrc: 'modifiedAssetSrc',
            loading: 'loading',
            croppedModifiedAssetSrc: 'croppedModifiedAssetSrc',
            imageCompressorState: 'imageCompressorState'
        }
    }); // Hook to get the asset editor store.

    const { compressionLevel } = useComponentStore<ImageCompressorState>('ImageCompressor', {
        fields: { compressionLevel: 'compressionLevel' }
    });

    const currentImageSrc = AssetEditorHelper.getAssetUrl(originalAssetSrc, modifiedAssetSrc, croppedModifiedAssetSrc);
    const initialImageSrcRef = useRef(currentImageSrc); // Ref to store the initial image src, this is needed when resetting the image src back to the original state.

    /** Gets the size of the original image in kilobytes. */
    const getOldImageSize = () => {
        return +ImageFileService.getFileSizeInKBFromBase64(initialImageSrcRef.current).toFixed(1);
    };

    /**
     * Returns a string representing the size of an image in either KB or MB.
     * If the file size is bigger than 1000 KB, the size will be converted to MB.
     * For example 100 KB or 1.5 MB.
     * @param sizeInKb The size of the image in kilobytes.
     * @returns A string representing the size of the image.
     */
    const getImageSizeTitle = (sizeInKb: number): string => {
        const thresholdInKb = 1000;

        if (sizeInKb > thresholdInKb) {
            const sizeInMb = sizeInKb / 1024; // Convert to megabytes
            return `${sizeInMb.toFixed(2)} MB`;
        } else {
            return `${sizeInKb} KB`;
        }
    };

    /**
     * Updates the state with the new size of the image.
     * @param imageSrcBase64 - The base64 of the image to get the total size.
     */
    const updateImageNewSize = (imageSrcBase64: string) => {
        const newImageSize = +(ImageFileService.getFileSizeInKBFromBase64(imageSrcBase64) ?? 0).toFixed(1);

        if (newImageSize) {
            setNewSize(newImageSize);
        }
    };

    /**
     * This function is responsible for checking if the image can be skipped from resizing depending if the input dimensions are equal to the original image size.
     */
    const canSkipImageResize = () => {
        return currentImageDimensions.width === maxImageDimensions.current.width && currentImageDimensions.height === maxImageDimensions.current.height;
    };

    /**
     * Resizes the image to the specified dimensions.
     * @returns The resized image as a base64 string.
     */
    const handleImageResize = async () => {
        const assetGalleryDialog: AssetGalleryDialogState | undefined = ComponentStore.get('AssetGalleryDialog');

        // Skip resizing if the image is already the max size or if the asset gallery is undefined.
        if (!assetGalleryDialog || canSkipImageResize()) {
            return initialImageSrcRef.current;
        }

        const { imageQuality } = assetGalleryDialog.data.assetData; // Get the image quality from the asset gallery.

        let imageElement = await AssetEditorHelper.getImageElement(initialImageSrcRef.current); // Get the image element from the base64 src.
        imageElement = await ImageExportHelper.resizeImage(imageElement, currentImageDimensions.width, currentImageDimensions.height, imageQuality); // Resize the image to the specified dimensions.

        return imageElement.src;
    };

    /**
     * Uploads the image to the cloud.
     * @param imageBase64Src The source of the image as a base64.
     * @returns The URL of the uploaded image.
     */
    const uploadImage = async (imageBase64Src: string) => {
        const url = await ImageFileService.uploadBase64(imageBase64Src);

        if (!url) {
            throw new Error();
        }

        return url;
    };

    /**
     * Compresses the image.
     * @param url The URL of the image to compress.
     * @returns The URL of the compressed image.
     */
    const compressImage = async (url: string) => {
        cancelTokenSource.current = axios.CancelToken.source();
        return await ImageModifierService.compressImage(url, compressionLevel, cancelTokenSource.current.token);
    };

    /**
     * Converts the image URL to a base64 string.
     * @param compressedUrl The URL of the compressed image.
     * @returns The base64 string of the compressed image.
     */
    const convertImageUrlToBase64 = async (compressedUrl: string) => {
        return await new Promise<string>((resolve, reject) => {
            ImageFileService.convertImageUrlToBase64(compressedUrl, (compressedBased64) => {
                if (!compressedBased64) {
                    reject();
                } else {
                    resolve(compressedBased64 as string);
                }
            });
        });
    };

    /**
     * Handles the compression of an image, by first uploading the image to the cloud, then compressing it, and finally converting it back to base64.
     * If the compression fails, the original image will be returned and an error message will be displayed.
     * @param imageBase64Src The source of the image as a base64 to be compressed.
     * Returns the base64 string of the compressed image.
     */
    const handleImageCompression = async (imageBase64Src: string, mode?: CompressorMode) => {
        const assetGalleryDialog: AssetGalleryDialogState | undefined = ComponentStore.get('AssetGalleryDialog');
        const assetEditor: AssetEditorState | undefined = ComponentStore.get('AssetEditor');
        const originalImageUrl = assetGalleryDialog?.value?.originalImage || assetGalleryDialog?.value?.url;
        const isAssetModified = assetEditor?.isAssetModified;

        if (mode === 'none') {
            return imageBase64Src; // Skip compression if the mode is 'none'.
        }

        // Don't upload image if the current asset is not modified and the original image URL is available.
        const url = originalImageUrl && !isAssetModified && canSkipImageResize() ? originalImageUrl : await uploadImage(imageBase64Src); // Upload the image to the cloud to get a URL.
        const compressedUrl = await compressImage(url); // Compress the image based on the URL.

        return await convertImageUrlToBase64(compressedUrl); // Convert the compressed image URL to a base64 string.
    };

    /**
     * Handles the export of the image.
     */
    const handleOnExport = async () => {
        try {
            ComponentStore.setModel('AssetEditor', 'loading', true); // Set loading to true to indicate that an operation is in progress and potentially disable any UI elements.

            const imageCompressor: ImageCompressorState | undefined = ComponentStore.get('ImageCompressor');
            const imageSrc = await handleImageResize(); // Resize the image to the specified dimensions to reduce file size.
            const compressedSrc = await handleImageCompression(imageSrc, imageCompressor?.mode); // Compress the image to reduce its file size even more.

            updateImageNewSize(compressedSrc); // Update the new size of the image to keep the state consistent with the actual image data.
            applyExport(compressedSrc); // Apply the export to the asset editor.
            handleNext(); // Go to the next step in the workflow, as the current step (image export) has been completed.
        } catch (error) {
            ComponentStore.setModel('AssetEditor', 'loading', false); // Set loading to false to indicate that an operation is finished and potentially disable any UI elements.
        }
    };

    /**
     * Determines the type of alert box to display based on the size of the image.
     * @param size - The size of the image in kilobytes.
     * @param isCompressionPreview - True if the alert box is for the compression preview, false otherwise. This is used to determine when the alert box should be in "info" mode instead of "warning" mode.
     * @returns The type of alert box to display ('error', 'warning', or 'success').
     */
    const getAlertBoxType = (size: number, isCompressionPreview?: boolean): AlertBoxType => {
        const maxSize = 100; // Size in kb.

        if (size === 0) {
            return 'error';
        }

        // Show info alert box if the image is bigger than the max size and the alert box is for the compression preview.
        if (size > maxSize && isCompressionPreview) {
            return 'info';
        }

        if (size >= maxSize) {
            return 'warning';
        }

        return 'success';
    };

    const handleAlertBox = () => {
        const alertBox = { ...DEFAULT_ALERT_Box };
        const oldImageSize = getOldImageSize();
        const oldImageSizeTitle = getImageSizeTitle(oldImageSize);

        switch (stepperStep) {
            case 0:
                {
                    alertBox.title = Translation.get('assetGalleryDialog.imageCompressor.fileSize', 'content-space') + ': ' + oldImageSizeTitle;
                    alertBox.type = getAlertBoxType(oldImageSize);
                }
                break;

            case 1:
                {
                    const compressionPercentage = ImageCompressorHelper.calculateCompressionPercentage(oldImageSize, newSize);
                    const compressionStatus =
                        newSize <= oldImageSize
                            ? Translation.get('assetGalleryDialog.imageCompressor.smaller', 'content-space')
                            : Translation.get('assetGalleryDialog.imageCompressor.bigger', 'content-space');

                    alertBox.title = compressionPercentage + '% ' + compressionStatus;
                    alertBox.subTitle = oldImageSizeTitle + ' -> ' + getImageSizeTitle(newSize);
                    alertBox.type = getAlertBoxType(newSize, true);
                }
                break;
        }

        setAlertBox(alertBox);
    };

    /**
     * Applies the export to the asset editor.
     * @param compressedSrc - The base64 string of the compressed image.
     */
    const applyExport = (compressedSrc: string) => {
        ComponentStore.setMultiModels('AssetEditor', [
            ['croppedModifiedAssetSrc', compressedSrc],
            ['imageCompressorState.compressionLevel', compressionLevel],
            ['imageCompressorState.isCompressed', true],
            ['previewAssetSrc', compressedSrc],
            ['loading', false]
        ]);
    };

    /**
     * Resets the preview asset src and the current image dimensions to their default state.
     */
    const setPreviewDefaultState = () => {
        const initialImageSrc = initialImageSrcRef.current; // Get the initial image src from the ref.

        ComponentStore.setMultiModels('AssetEditor', [
            ['croppedModifiedAssetSrc', initialImageSrc], // Reset the cropped modified asset src to the none exported asset src.
            ['previewAssetSrc', initialImageSrc], // Reset the preview asset src to the previous/none exported asset src.
            ['imageCompressorState.isCompressed', false] // Reset the state of the compressor to false.
        ]);

        setCurrentImageDimensions(maxImageDimensions.current); // Reset the current image dimensions to the max image dimensions.
        updateImageNewSize(initialImageSrc); // Reset the new size of the image based on the previous/none exported asset src.
    };

    const onCancel = () => {
        cancelTokenSource.current.cancel();

        if (stepperStep >= 1) {
            handleBack();
        }

        setPreviewDefaultState();
    };

    /**
     * Gets the dimensions of the image from the base64 src and update current image dimensions state.
     */
    const handleCurrentImageDimensions = async () => {
        const { width, height } = await ImageFileService.getBase64ImageDimensions(initialImageSrcRef.current); // Get the dimensions of the image from the base64 src.

        maxImageDimensions.current = { width, height }; // Set max image dimensions.
        setCurrentImageDimensions({ width, height }); // Set current image dimensions.
    };

    /**
     * Updates the manual input for the specified dimension type (width or height) with the provided value.
     * @param type - The type of dimension to update (width or height).
     * @param value - The new value for the specified dimension.
     */
    const handleManualInput = (type: 'width' | 'height', value: string) => {
        // If the value is '', clear the input field.
        if (value === '') {
            const oppositeType = type === 'width' ? 'height' : 'width'; // This is to make sure that the opposite type is also cleared.
            setCurrentImageDimensions({ ...currentImageDimensions, [type]: '', [oppositeType]: '' });
            return;
        }

        const { width: maxWidth, height: maxHeight } = maxImageDimensions.current;
        const aspectRatio = ImageFileService.getImageRatio(maxWidth, maxHeight);

        const unValidatedManualInput = AECropperHelper.getManualInput(type, value, { w: maxWidth, h: maxHeight }, aspectRatio);
        const { w, h } = AECropperHelper.validateManualInput(unValidatedManualInput.w, unValidatedManualInput.h, maxWidth, maxHeight);

        const isDimensionWithinBounds = AECropperHelper.isDimensionWithinBounds(w, h, maxWidth, maxHeight); // Make sure the new dimensions are not larger than the max image dimensions.

        if (isDimensionWithinBounds) {
            setCurrentImageDimensions({ width: w, height: h });
        }
    };

    useMemo(() => {
        handleAlertBox();
    }, [stepperStep, newSize]);

    useEffect(() => {
        ComponentStore.setData('ImageCompressor', {
            compressionLevel: imageCompressorState?.compressionLevel
        });

        setPreviewDefaultState();
        handleCurrentImageDimensions(); // Get the dimensions of the image from the base64 src and update current image dimensions state.

        return () => {
            const assetEditor: AssetEditorState | undefined = ComponentStore.get('AssetEditor');

            if (assetEditor && assetEditor.imageCompressorState?.isCompressed) {
                ComponentStore.setModel('AssetEditor', 'imageCompressorState.isCompressed', false); // Make sure state of the compressor is back to false one the component is unmounted.
            }

            ComponentStore.remove('ImageCompressor'); // Remove the ImageCompressor model from the store when the component is unmounted.
        };
    }, []);

    return (
        <>
            <AssetEditorControllerStepper
                steps={imageCompressorSteps}
                isLoading={loading}
                alertBox={alertBox}
                disableStepLabelClickEvent={true}
                loadingChildren={
                    <StepperLoading onCancelClick={onCancel} text={Translation.get('assetGalleryDialog.assetEditor.exporting', 'content-space')} />
                }>
                {stepperStep === 0 && (
                    <div className="image-compressor-stepper__content">
                        <CropInputRow
                            width={currentImageDimensions.width}
                            height={currentImageDimensions.height}
                            text={Translation.get('assetGalleryDialog.assetEditor.outputSize', 'content-space')}
                            showCropLinkIcon
                            onWidthChange={(e) => handleManualInput('width', e.target.value)}
                            onHeightChange={(e) => handleManualInput('height', e.target.value)}
                        />
                        <div className="image-compressor-stepper__content__compression">
                            {Translation.get('assetGalleryDialog.assetEditor.compression', 'content-space')}
                        </div>
                        <ImageCompressorMode classes={{ toggleButtonGroupContainer: 'image-compressor-stepper__content__toggleBtnGroup' }} />
                        <AssetEditorActionRow className="image-compressor-stepper__content__action-row">
                            <Button
                                disabled={loading || !currentImageDimensions?.height || !currentImageDimensions?.width}
                                startIcon={<Icon>auto_awesome</Icon>}
                                onClick={handleOnExport}
                                variant="contained"
                                color="primary">
                                {Translation.get('actions.apply', 'common')}
                            </Button>
                        </AssetEditorActionRow>
                    </div>
                )}
                {stepperStep === 1 && (
                    <AssetEditorActionRow>
                        <Button onClick={onCancel} variant="text">
                            {Translation.get('actions.reset', 'common')}
                        </Button>
                    </AssetEditorActionRow>
                )}
            </AssetEditorControllerStepper>
        </>
    );
};

export default ImageCompressorController;
